.ButtonBody {
    background: #FB1856;
    box-shadow: 0px 2px 0px #921934;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 600;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 24px;
    cursor: pointer;
    position: relative;
}

.PressedColor {
    background: #D61449;
}

.Detail {
    border-radius: 2px;
    width: 6px;
    height: 3px;
    position: absolute;
    background: #FFFFFF;
}

.DLeft {
    left: 3px;
    top: 4px;
    transform: matrix(0.92, -0.39, 0.39, 0.92, 0, 0);
}

.DRight {
    width: 3px;
    height: 6px;
    right: 5px;
    top: 3px;
    transform: rotate(-67.28deg);
}