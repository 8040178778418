.ModalBody {
    background-color: white;
    border-radius: 12px;
    min-width: 311px;
    min-height: 250px;
    max-width: 555px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
}

.Loader {
    border: 8px solid #D8D8D8; /* Light grey */
    border-top: 8px solid #1EB1CF; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin-bottom: 20px;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.Title {
    color: #181818;
    font-size: 28px;
    font-family: Changa;
    font-weight: bold;
    margin: 0px;
}

.Text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin: 0px;
}

.TextBox {
    text-align: center;
    max-height: 82px;
    margin-bottom: 20px;
}

.GreenTick {
    width: 80px;
    height: 80px;
}