.BackgroundDesktop {
    background-image: url("../../../public/images/desktopBlueBG.svg");
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.BackgroundMobile {
    width: 100vw;
    height: 100vh;
    background-image: url("../../../public/images/mobileBlueBg.svg");
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}